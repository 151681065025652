import React, { useContext } from "react"

import { handleAuthentication } from "../utils/auth"
import { navigate } from "@reach/router"
import { AppContext } from "../contexts/app.context"
import { isBrowser } from "../utils/browser"

const Callback = () => {
  const { updateUser, updateLoading } = useContext(AppContext)

  if (!isBrowser()) {
    return <></>
  }

  updateLoading(true)

  handleAuthentication(async userData => {
    updateUser(userData)

    setTimeout(() => {
      updateLoading(false)

      navigate("/dashboard")
    }, 500)
  })

  return <></>
}

export default Callback
